
import FeatherIcon from "@/helpers/FeatherIcon"
import PugImgInsights from "@/dashboard/pug-img/PugImgInsights"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PugBadge from '@/dashboard/badges/pug-badge'
import PugButton from '@/dashboard/buttons/pug-button'

export default {
  name: 'CardWithImage',
  components: {
    FeatherIcon,
    PugImgInsights,
    FontAwesomeIcon,
    PugBadge,
    PugButton
  },
  props: {
    title: {
      type: String,
      default: "Card Title"
    },
    description: {
      type: String,
      default: "Card description"
    },
    /**
    * If you put a link here the description is clickable
    */
    description_link: {
      type: String,
      default: null
    },
    buttons: {
      type: Array,
      default: () => {
        return [
        ]
      }
    },
    /**
    * Tag to show to the right of text
    */
    tag: {
      type: Object
    },
    /**
    * Tags that go on top of the image
    */
    cardImageTagItems: {
      type: Array
    },
    /**
    * Image object from the db
    */
    image: {
      type: Object,
    },
    /**
    * optionally force which size of image to show with PugImg
    */
    imageSizeToShow: {
      type: String,
    },
    showEditButton: {
      type: Boolean,
      default: false
    },
    dataForCard: {
      type: Object
    },
  },
  methods: {
    onClick(button) {
      /**
       * onClick event.
       *
       * @event onClick
       * @type {object}
       */
      this.$emit('onClick', button)
    },
    onSettingsButtonClick(e, item) {
      e.stopPropagation()

      /**
             * onSettingsButtonClick event.
             *
             * @event onSettingsButtonClick
             * @type {object}
             */
      this.$emit('onSettingsButtonClick', item)
    },
    onCardClicked(data) {
      /**
       * Jon added this to test.
       * Event triggered when clicking on an component inside the sidebar
       * it is sent along with the component that triggered it
       *
       * @event onCardClicked
       * @type {object}
       */
      this.$emit("onCardClicked", data)
    },
  }
}
